<template>
  <div class="cashier" @click="hideModal">
    <div class="header">
      <div class="left">
        <img src="../assets/icon_logo_blue.png" alt=""> | <span>支付中心</span>
      </div>
      <img v-if="user" :src="user.MemberHeadPic==null?require('../assets/img_head24.png'):user.MemberHeadPic" alt=""
        class="right">
    </div>
    <div class="banner">
      <div class="banner_in">
        <img src="../assets/pay_bg200.png" alt="">
        <div class="word">
          <div>订单已生成！请尽快完成付款</div>
          <div class="good">
            <div class="item"><img src="../assets/pay_icon1.png" alt=""><span>律师多</span></div>
            <div class="item"><img src="../assets/pay_icon2.png" alt=""><span>响应快</span></div>
            <div class="item"><img src="../assets/pay_icon3.png" alt=""><span>监管好</span></div>
            <div class="item"><img src="../assets/pay_icon4.png" alt=""><span>服务便</span></div>
            <div class="item"><img src="../assets/pay_icon5.png" alt=""><span>价格省</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="main_in">
        <div class="top">
          <div class="left">
            <div>{{orderInfo.OrderTitle=='文字咨询'?'图文咨询':orderInfo.OrderTitle}}</div>
            <div>
              <div><span>{{price}}</span>元</div> 原价：{{orderInfo.OrderNeedPay}}元
            </div>
            <div>
              <span>优惠/折扣</span>
              <div class="select" v-if="yhList.length>0">
                <!-- <div @click.stop="selectFun" v-if="yhList.length>0">
                  <div v-if="yh.CouponsName!='暂不使用优惠'">¥{{yh.Price}} {{yh.CouponsName}}</div>
                  <div v-else>{{yh.CouponsName}}</div>
                  <img src="../assets/icon_arrows_up_normal.png" alt="">
                </div> -->
                <el-select v-model="yhlabel" placeholder="请选择" popper-class="cashier_select" @change="choseChange">
                  <el-option v-for="(item,index) in yhList" :key="index" :label="item.name" :value="index">
                    <div class="title" style="line-height:38px;" v-if="item.CouponsName=='暂不使用优惠'">{{item.CouponsName}}
                    </div>
                    <div class="title" style="line-height:38px;"
                      v-else-if="item.CouponsName=='新用户图文咨询9.9元'||item.CouponsName=='新用户1个月会员38元'">¥{{item.Price}}
                      {{item.CouponsName}}</div>
                    <div class="title" v-else-if="item.Price==0&&item.CouponsName!='暂不使用优惠'">免费 {{item.CouponsName}}
                    </div>
                    <div class="title" v-else>¥{{item.Price}} {{item.CouponsName}}</div>
                    <div class="time"
                      v-if="item.CouponsName!='暂不使用优惠'&&item.CouponsName!='新用户图文咨询9.9元'&&item.CouponsName!='新用户1个月会员38元'">
                      {{item.EndDate}}到期</div>
                    <!-- <img src="../assets/pay_icon_tick10.png" alt="" v-if="item.YhGuid==yh.YhGuid"> -->
                  </el-option>
                </el-select>

                <!-- <el-scrollbar class="select_div" v-show="showSelect">
                  <div class="item" v-for="(item,index) in yhList" @click="choseYH(index)">
                    <div class="title" style="line-height:38px;" v-if="item.CouponsName=='暂不使用优惠'">{{item.CouponsName}}
                    </div>
                    <div class="title" style="line-height:38px;"
                      v-else-if="item.CouponsName=='新用户图文咨询9.9元'||item.CouponsName=='新用户1个月会员38元'">¥{{item.Price}}
                      {{item.CouponsName}}</div>
                    <div class="title" v-else>¥{{item.Price}} {{item.CouponsName}}</div>
                    <div class="time"
                      v-if="item.CouponsName!='暂不使用优惠'&&item.CouponsName!='新用户图文咨询9.9元'&&item.CouponsName!='新用户1个月会员38元'">
                      {{item.EndDate}}到期</div>
                    <img src="../assets/pay_icon_tick10.png" alt="" v-if="item.YhGuid==yh.YhGuid">
                  </div>
                </el-scrollbar> -->
              </div>
              <div class="select_no" v-if="yhList.length==0">
                <div>
                  <div>暂无可用优惠券/折扣</div>
                  <img src="../assets/icon_arrows_up_normal.png" alt="">
                </div>
              </div>
            </div>
            <img src="../assets/pay_icon_tick_default.png" alt="">
          </div>
          <div class="right">
            <div class="title">购买须知</div>
            <div class="tip" v-if="orderInfo.OrderTitle.indexOf('文字')>=0">
              <div>1、非律师原因退款，平台将扣除30%违约金。</div>
            </div>
            <div class="tip" v-if="orderInfo.OrderTitle.indexOf('电话')>=0">
              <div>1、为保护您的隐私信息，律师来电将显示网络号码（950开头）；</div>
              <div>2、60分钟内您可不限次数拨打律师电话；</div>
              <div>3、非律师原因退款，平台将扣除30%违约金。</div>
            </div>
            <div class="tip" v-if="orderInfo.OrderTitle.indexOf('视频')>=0">
              <div>1、60分钟内您可不限次数发起视频通话；</div>
              <div>2、非律师原因退款，平台将扣除30%违约金。</div>
            </div>
            <div class="tip" v-if="orderInfo.OrderTitle.indexOf('文书')>=0">
              <div>1、15天内免费修改，包含文字、电话沟通；</div>
              <div>2、非律师原因退款，平台将扣除30%违约金。</div>
            </div>
            <div class="tip" v-if="orderInfo.OrderTitle.indexOf('律师函')>=0">
              <div>1、15天内免费修改，包含文字、电话沟通；</div>
              <div>2、非律师原因退款，平台将扣除30%违约金。</div>
            </div>
            <div class="tip" v-if="orderInfo.OrderTitle.indexOf('私人律师')>=0||orderInfo.OrderTitle.indexOf('私人婚姻律师')>=0||orderInfo.OrderTitle.indexOf('企业律师')>=0||orderInfo.OrderTitle.indexOf('维权律师')>=0||orderInfo.OrderTitle.indexOf('财产律师')>=0">
              <div>1、非律师原因退款，平台将扣除30%违约金。</div>
            </div>
            <div class="tip" v-if="orderInfo.OrderTitle.indexOf('案件')>=0">
              <div>1、案件确认需要支付{{casePrice}}元作为案件确认金，此确认金跟律师代理费无关，律师代理费需跟律师沟通了解案情后额外支付。一旦确认案件，此确认金不予退还！</div>
            </div>
            <div class="tip" v-if="orderInfo.OrderTitle.indexOf('合同')>=0">
              <div>1、您可在个人中心-合同模板中查看已购买的合同模板。</div>
            </div>
            <div class="tip" v-if="orderInfo.OrderTitle.indexOf('会员')>=0">
              <div>1、会员权益包括：每月可享3张价值38元的图文咨询优惠券，1张价值38元的电话咨询优惠券，1张价值98元的私人律师优惠券，会员有效期内，您可免费不限次下载任意合同模板；</div>
              <div>2、开通会员后，当月会员优惠券立即到账，之后按会员周期发放优惠券；</div>
              <div>3、退款规则：若您未使用任何会员权益，可申请全额退款；若已使用会员权益，不支持退款。</div>
            </div>
          </div>
        </div>
        <div class="member" :class="buymember?'active':''" @click="buymemberFun"
          v-if="AssociatorState!='已开通'&&orderInfo.OrderTitle.indexOf('会员')<0&&!isNewUser">
          <div class="lt">限时特惠</div>
          <img
            :src="buymember?require('../assets/pay_icon_tick_default.png'):require('../assets/pay_icon_tick_normal.png')"
            alt="" class="rb">
          <div class="member_top">
            <div class="left">
              <span>1个月会员</span><span>{{member1.ProductMarketPrice}}</span><span>元</span><span>原价：{{member2.ProductMarketPrice}}元</span>
            </div>
            <div class="right">预计可省 250元 /月</div>
          </div>
          <div class="member_bottom">
            <div class="item">
              <img src="../assets/pay_icon_free1.png" alt="">
              <div>3次免费图文咨询</div>
            </div>
            <div class="item">
              <img src="../assets/pay_icon_free2.png" alt="">
              <div>1次特价电话咨询</div>
            </div>
            <div class="item">
              <img src="../assets/pay_icon_free3.png" alt="">
              <div>30天超值私人律师</div>
            </div>
            <div class="item">
              <img src="../assets/pay_icon_free4.png" alt="">
              <div>海量模板免费下载</div>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div v-if="total>0">支付成功后</div>
          <div v-else>下单成功后</div>
          <div v-if="orderInfo.OrderTitle.indexOf('文字')>=0">律师将在<span>6秒内</span>为您提供<span>60分钟</span>的图文咨询服务</div>
          <div v-if="orderInfo.OrderTitle.indexOf('电话')>=0">律师将在<span>10分钟内</span>拨打您尾号
            {{user.MemberMoblie.substring(7,11)}} 的手机，为您提供<span>60分钟</span>的电话咨询服务</div>
          <div v-if="orderInfo.OrderTitle.indexOf('视频')>=0">律师将在<span>10分钟内</span>向您发起视频通话，为您提供<span>60分钟</span>的视频咨询服务
          </div>
          <div v-if="orderInfo.OrderTitle.indexOf('文书')>=0">律师将在<span>约定时间</span>向您交付文书</div>
          <div v-if="orderInfo.OrderTitle.indexOf('律师函')>=0">律师将在<span>约定时间</span>向您交付律师函</div>
          <div v-if="orderInfo.OrderTitle.indexOf('私人律师')>=0||orderInfo.OrderTitle.indexOf('私人婚姻律师')>=0||orderInfo.OrderTitle.indexOf('企业律师')>=0||orderInfo.OrderTitle.indexOf('维权律师')>=0||orderInfo.OrderTitle.indexOf('财产律师')>=0">律师将为您提供1对1<span>专属咨询服务</span></div>
          <div v-if="orderInfo.OrderTitle.indexOf('案件')>=0">平台法务将在<span>1个工作日内</span>给你来电核实需求，<span>根据您的要求安排本地律师</span>
          </div>
          <div v-if="orderInfo.OrderTitle.indexOf('合同')>=0&&buymember">您可<span>免费不限次</span>下载<span>任意</span>合同模板</div>
          <div v-if="orderInfo.OrderTitle.indexOf('合同')>=0&&!buymember">您可<span>不限次</span>下载<span>当前</span>合同模板</div>
          <div v-if="orderInfo.OrderTitle.indexOf('会员')>=0">会员权益<span>立即生效</span></div>
          <div class="er" :class="paytype=='wx'?'wxer':'zfber'" v-show="total>0">
            <div id="qrcode" ref="qrcode" v-show="paytype=='wx'"></div>
            <iframe width="140" height="140" :src="zfbUrl" frameborder="0" v-show="paytype=='zfb'"></iframe>
          </div>
          <div class="paytype" v-if="total>0">
            <div class="item" :class="paytype=='wx'?'wxactive':''" @click="chosePay('wx')">
              <img src="../assets/pay_icon_wenchat.png" alt=""><span>微信支付</span>
            </div>
            <div class="item" :class="paytype=='zfb'?'zfbactive':''" @click="chosePay('zfb')">
              <img src="../assets/pay_icon_zhifubao.png" alt=""><span>支付宝支付</span>
            </div>
          </div>
          <div class="paybtn" @click="payzero" v-else>立即下单</div>
          <div class="payprice">
            支付金额
            <div><span>{{total}} </span><span>元</span> <span>本单优惠
                {{totalYH}} 元</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FootBox></FootBox>
  </div>
</template>
<script>
  import QRCode from "qrcodejs2"
  import FootBox from '@/components/FootBox'
  import {
    GetCashCouponsList,
    CommonPay,
    GetPaymentResult,
    QuickAssociatorOrder,
    GetMemberAssociatorPCList,
    notifyurl,
    paysystem,
    GetCasePrice
  } from '@/api'
  var payTimer = null,
    loading
  export default {
    name: 'cashier',
    data() {
      return {
        showSelect: false,
        yh: {},
        yhList: [],
        paytype: 'wx',
        buymember: false,
        user: null,
        orderInfo: {
          OrderTitle: '',
          OrderNeedPay: 0,
        },
        price: 0,
        content: {
          type: String,
          default: '支付码'
        },
        qrcode: null,
        total: 0,
        memberYH: [],
        memberOrder: null,
        member1: {
          ProductMarketPrice: 0
        },
        member2: {
          ProductMarketPrice: 0
        },
        casePrice: 0,
        zfbUrl: '',
        isNewUser: false,
        totalYH: 0,
        AssociatorState: '',
        yhlabel: 0
      }
    },
    watch: {
      buymember(newVal) {
        if (newVal) {
          this.watchMember()
        } else {
          if (this.orderInfo.OrderTitle == '文字咨询'&&this.orderInfo.LawyerGuid==null) {
            this.yhList.splice(0, 3)
            this.choseYH(0)
          } else if (this.orderInfo.OrderTitle == '电话咨询'&&this.orderInfo.LawyerGuid==null) {
            this.yhList.splice(0, 1)
            this.choseYH(0)
          } else if (this.orderInfo.OrderTitle == '私人律师'&&this.orderInfo.LawyerGuid==null) {
            this.yhList.splice(0, 1)
            this.choseYH(0)
          } else if (this.$route.query.CaseGuid) {
            this.price = this.orderInfo.OrderNeedPay
            this.total = this.price
            this.getPay()
          } else if (this.orderInfo.OrderTitle.indexOf('合同模板') >= 0) {
            this.price = this.orderInfo.OrderNeedPay
            this.total = this.price
            this.getPay()
          }else{
            this.choseYH(this.yhlabel)
          }
          if (this.yhList.length == 1 && this.yhList[0].CouponsName == '暂不使用优惠') {
            this.yhList = []
          }
        }
      },
    },
    mounted() {
      _hmt.push(['_trackPageview', '/cashier'])
      loading = this.$loading();

      this.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
      this.AssociatorState = this.user.AssociatorState
      this.isMember().then(res => {
        this.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
        this.AssociatorState = this.user.AssociatorState
      })
      if (this.$route.query.first && this.$route.query.first == 1) {
        this.isNewUser = true
      }
      var title = ''
      if (this.$route.query.CaseGuid) {
        this.orderInfo.OrderTitle = '案件确认'
        GetCasePrice().then(res => {
          if (res.state == 0) {
            this.casePrice = res.data
            this.orderInfo.OrderNeedPay = res.data
            this.price = res.data
            this.total = res.data
            loading.close();
          }
        })

      } else {
        this.orderInfo = JSON.parse(localStorage.getItem('payOrder'))
        if (this.orderInfo.OrderTitle.indexOf('文字') >= 0) {
          title = '文字'
        } else if (this.orderInfo.OrderTitle.indexOf('电话') >= 0) {
          title = '电话'
        } else if (this.orderInfo.OrderTitle.indexOf('视频') >= 0) {
          title = '视频'
        } else if (this.orderInfo.OrderTitle.indexOf('私人律师') >= 0) {
          title = '私人律师'
        } else if (this.orderInfo.OrderTitle.indexOf('文书') >= 0) {
          title = '文书'
        } else if (this.orderInfo.OrderTitle.indexOf('会员') >= 0) {
          title = '会员'
        } else {
          title = this.orderInfo.OrderTitle
        }

      }
      GetMemberAssociatorPCList({
        "memberGuid": this.user.MemberGuid
      }).then(res => {
        if (res.state == 0) {
          for (var i = 0; i < res.data.length; i++) {
            if (res.data[i].ProductTitle == '首月特惠') {
              this.member1 = res.data[i]
            }
            if (res.data[i].ProductTitle == '1个月会员') {
              this.member2 = res.data[i]
            }
          }
          if (this.$route.query.member && this.memberOrder == null) {
            var data = {
              "productGuid": this.member1.ProductGuid,
              "memberGuid": this.user.MemberGuid,
              "orderFrom": localStorage.getItem('orderfrom'),
              "otherOrderOrderGuid": this.orderInfo.OrderGuid,
            }
            QuickAssociatorOrder(data).then(res => {
              if (res.state == 0) {
                this.memberOrder = res.data
                if (this.$route.query.CaseGuid || this.orderInfo.OrderTitle.indexOf('合同模板') >= 0) {
                  this.price = this.orderInfo.OrderNeedPay
                  this.total = this.orderInfo.OrderNeedPay
                  this.getPay()
                  loading.close();
                } else {
                  this.getYH(title, 'default')
                }
              } else {
                this.$message(res.des)
              }
            })
          } else {
            if (this.$route.query.CaseGuid || this.orderInfo.OrderTitle.indexOf('合同模板') >= 0) {
              this.price = this.orderInfo.OrderNeedPay
              this.total = this.orderInfo.OrderNeedPay
              this.getPay()
              loading.close();
            } else {
              this.getYH(title, 'default')
            }
          }

        }
      })
      window.addEventListener("popstate", function (e) {
        clearInterval(payTimer)
      }, false)
    },
    beforeRouteLeave(to, from, next) {
      clearInterval(payTimer)
      next()
    },
    methods: {
      watchMember() {
        if (this.buymember) {
          if (this.$route.query.CaseGuid) { //案件
            if (this.memberOrder == null) {
              var data = {
                "productGuid": this.member1.ProductGuid,
                "memberGuid": this.user.MemberGuid,
                "orderFrom": localStorage.getItem('orderfrom'),
                "otherOrderOrderGuid": this.orderInfo.OrderGuid,
              }
              QuickAssociatorOrder(data).then(res => {
                if (res.state == 0) {
                  this.memberOrder = res.data
                  this.total = (Number(this.member1.ProductMarketPrice) * 1000 + Number(this.orderInfo
                    .OrderNeedPay) * 1000) / 1000
                  this.getPay()
                } else {
                  this.$message(res.des)
                }
              })
            } else {
              this.total = (Number(this.member1.ProductMarketPrice) * 1000 + Number(this.orderInfo.OrderNeedPay) * 1000) /
                1000
              this.getPay()
            }
          } else if (this.orderInfo.OrderTitle.indexOf('合同模板') >= 0) {
            this.price = 0
            if (this.memberOrder == null) {
              var data = {
                "productGuid": this.member1.ProductGuid,
                "memberGuid": this.user.MemberGuid,
                "orderFrom": localStorage.getItem('orderfrom'),
                "otherOrderOrderGuid": this.orderInfo.OrderGuid,
              }
              QuickAssociatorOrder(data).then(res => {
                if (res.state == 0) {
                  this.memberOrder = res.data
                  this.total = Number(this.member1.ProductMarketPrice)
                  this.getPay()
                } else {
                  this.$message(res.des)
                }
              })
            } else {
              this.total = Number(this.member1.ProductMarketPrice)
              this.getPay()
            }
          } else {
            if (this.$route.query.from && this.$route.query.from == 'ask') {
              var date1 = new Date();
              var date2 = new Date(date1);
              date2.setDate(date1.getDate() + 30);
              var EndDate = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate() + ' ' + date1
                .getHours() + ':' + date1.getMinutes() + ':' + date1.getSeconds()
              if (this.orderInfo.OrderTitle == '文字咨询') {
                var obj1 = {
                  CouponType: "会员",
                  CouponsName: "（会员专享）图文咨询优惠券",
                  CouponsType: "",
                  EndDate: EndDate,
                  Price: 38,
                  UseState: "",
                  YhGuid: "4",
                  name: '¥38 （会员专享）图文咨询优惠券'
                }
                var obj2 = {
                  CouponType: "会员",
                  CouponsName: "（会员专享）图文咨询优惠券",
                  CouponsType: "",
                  EndDate: EndDate,
                  Price: 38,
                  UseState: "",
                  YhGuid: "5",
                  name: '¥38 （会员专享）图文咨询优惠券'
                }
                var obj3 = {
                  CouponType: "会员",
                  CouponsName: "（会员专享）图文咨询优惠券",
                  CouponsType: "",
                  EndDate: EndDate,
                  Price: 38,
                  UseState: "",
                  YhGuid: "6",
                  name: '¥38 （会员专享）图文咨询优惠券'
                }
                this.memberYH = []
                this.memberYH.push(obj1)
                this.memberYH.push(obj2)
                this.memberYH.push(obj3)
                this.yhList = this.memberYH.concat(this.yhList)
                this.yh = obj1
                this.yhlabel=obj1.name
              } else if (this.orderInfo.OrderTitle == '电话咨询') {
                var obj1 = {
                  CouponType: "会员",
                  CouponsName: "（会员专享）电话咨询优惠券",
                  CouponsType: "",
                  EndDate: EndDate,
                  Price: 38,
                  UseState: "",
                  YhGuid: "4",
                  name: '¥38 （会员专享）电话咨询优惠券'
                }
                this.memberYH = []
                this.memberYH.push(obj1)
                this.yhList = this.memberYH.concat(this.yhList)
                this.yh = obj1
                this.yhlabel=obj1.name
              } else if (this.orderInfo.OrderTitle.indexOf('私人律师') >= 0) {
                var obj1 = {
                  CouponType: "会员",
                  CouponsName: "（会员专享）私人律师优惠券",
                  CouponsType: "",
                  EndDate: EndDate,
                  Price: 98,
                  UseState: "",
                  YhGuid: "4",
                  name: '¥38 （会员专享）私人律师优惠券'
                }
                this.memberYH = []
                this.memberYH.push(obj1)
                this.yhList = this.memberYH.concat(this.yhList)
                this.yh = obj1
                this.yhlabel=obj1.name
              }


            }else{

            }
            var has = 0
            for (var i = 0; i < this.yhList.length; i++) {
              if (this.yhList[i].CouponsName == '暂不使用优惠') {
                has = 1
              }
            }
            if (has == 0) {
              var obj = {
                CouponType: "",
                CouponsName: "暂不使用优惠",
                CouponsType: "",
                EndDate: "",
                Price: 0,
                UseState: "",
                YhGuid: "1",
                name: '暂不使用优惠'
              }
              this.yhList.push(obj)
            }
            // if (this.yhList.length <= 5) {
            //   document.getElementsByClassName('select_div')[0].style.height = 68 * this.yhList.length + 'px'
            // } else {
            //   document.getElementsByClassName('select_div')[0].style.height = 68 * 5 + 'px'
            // }
            if (this.memberOrder == null) {
              var data = {
                "productGuid": this.member1.ProductGuid,
                "memberGuid": this.user.MemberGuid,
                "orderFrom": localStorage.getItem('orderfrom'),
                "otherOrderOrderGuid": this.orderInfo.OrderGuid,
              }
              QuickAssociatorOrder(data).then(res => {
                if (res.state == 0) {
                  this.memberOrder = res.data
                  this.choseYH(0, 'nopay')
                } else {
                  this.$message(res.des)
                }
              })
            } else {
              this.choseYH(0, 'nopay')
            }
          }
        }
      },
      getYH(type, from) {
        var direction = 1
        if (this.$route.query.from && this.$route.query.from == 'ask') {
          direction = 0
        }
        var data = {
          "memberGuid": this.user.MemberGuid,
          "type": type,
          "direction": direction
        }
        GetCashCouponsList(data).then(res => {
          loading.close();
          if (res.state == 0) {
            for (var i = 0; i < res.data.length; i++) {
              if (res.data[i].Price == 0) {
                res.data[i].name = '免费' + ' ' + res.data[i].CouponsName
              } else {
                res.data[i].name = '¥' + res.data[i].Price + ' ' + res.data[i].CouponsName
              }
            }
            this.yhList = res.data
            if (res.data.length == 0) {
              this.price = this.orderInfo.OrderNeedPay
              this.total = this.price
            } else {
              var p = (Number(this.orderInfo.OrderNeedPay) * 1000 - Number(res.data[0].Price) * 1000) / 1000
              this.price = p > 0 ? p : 0
              this.total = this.price
              if (res.data[0].Price == 0) {
                this.total = 0
                this.price = 0
              }
              this.yh = res.data[0]
              this.yhlabel=res.data[0].name
              var obj = {
                CouponType: "",
                CouponsName: "暂不使用优惠",
                CouponsType: "",
                EndDate: "",
                Price: 0,
                UseState: "",
                YhGuid: "1",
                name: '暂不使用优惠'
              }
              this.yhList.push(obj)
            }

            // if (this.yhList.length <= 5) {
            //   document.getElementsByClassName('select_div')[0].style.height = 68 * this.yhList.length + 'px'
            // } else {
            //   document.getElementsByClassName('select_div')[0].style.height = 68 * 5 + 'px'
            // }
            if (!(from == 'default' && this.$route.query.member)) {
              this.getPay()
              this.watchMember()
            } else {
              this.buymember = this.$route.query.member == 0 ? false : true
            }

          } else {
            this.$message(res.des)
          }
        })
      },
      getPay() {
        if (this.orderInfo.OrderTitle.indexOf('合同模板') >= 0 && this.buymember) {
          this.totalYH = this.orderInfo.OrderNeedPay
        } else {
          this.totalYH = this.yh.Price ? this.orderInfo.OrderNeedPay - this.yh.Price > 0 ? this.yh.Price : this
            .orderInfo.OrderNeedPay : 0
          if (this.yh.Price == 0 && this.yh.CouponsName != '暂不使用优惠') {
            this.totalYH = this.orderInfo.OrderNeedPay
          }
        }
        if (this.buymember) {
          var p = 0
          if (Number(this.member2.ProductMarketPrice) - Number(this.member1.ProductMarketPrice) > 0) {
            p = (Number(this.member2.ProductMarketPrice) * 1000 - Number(this.member1.ProductMarketPrice) * 1000) / 1000
          }
          this.totalYH = (Number(this.totalYH) * 1000 + p * 1000) / 1000
        }
        var yhGuid = JSON.stringify(this.yh) == '{}' || this.yh.YhGuid == 1 || this.yh.YhGuid == 2 || this.yh.YhGuid ==
          3 || this.yh.CouponType == '首单特惠' ?
          '' : this.yh.YhGuid
        if (this.yh.CouponType == '会员') { //组合支付消耗券
          yhGuid = '99999999-9999-9999-9999-999999999999'
        }
        var money = this.orderInfo.OrderNeedPay
        if (this.total > 0) {
          var paytype = '04',
            orderGuid = this.orderInfo.OrderGuid
          if (this.orderInfo.OrderTitle.indexOf('会员') >= 0) {
            paytype = '20'
          } else if (this.orderInfo.OrderTitle.indexOf('合同模板') >= 0) {
            paytype = '21'
          } else if (this.orderInfo.OrderTitle.indexOf('案件') >= 0) {
            paytype = '09'
            orderGuid = this.$route.query.CaseGuid
            yhGuid = ''
          }
          if (this.orderInfo.OrderTitle.indexOf('合同模板') >= 0 && this.buymember) {
            money = Number(this.member1.ProductMarketPrice)
            paytype = '22'
          } else if (this.buymember) {
            paytype = '22'
            orderGuid = this.memberOrder.OrderGuid
            money = (Number(this.member1.ProductMarketPrice) * 1000 + Number(this.orderInfo.OrderNeedPay) * 1000) / 1000
          }
          var data = {
            "orderGuid": orderGuid,
            "yhGuid": yhGuid,
            "money": money,
            "paytype": paytype,
            "bankname": this.paytype,
            "webremark": this.yh.CouponType == '首单特惠' ? this.orderInfo.OrderTitle + '-首单特惠' : this.orderInfo
              .OrderTitle,
            "pc": ""
          }
          CommonPay(data).then(res => {
          console.log(res)
            if (res.state == 0) {
              if (this.paytype == 'wx') {
                var url = res.data.result
                this.qrcodeFun(url)
                this.sucessFun(res.data.payCustomerId)
              } else {
                this.zfbPay(res.data.payCustomerId)
                this.sucessFun(res.data.payCustomerId)
              }

            }
          })
        }

      },
      zfbPay(payCustomerId) {
        this.zfbUrl = paysystem + '?payid=' + payCustomerId + "&totalpay=" + this.total + "&notifyurl=" + notifyurl +
          "&returnurl=" + "&subjectname=" + this.orderInfo.OrderTitle + "&app_pay=";
      },
      payzero() {
        var yhGuid = JSON.stringify(this.yh) == '{}' || this.yh.YhGuid == 1 || this.yh.YhGuid == 2 || this.yh.YhGuid ==
          3 ?
          '' : this.yh.YhGuid
        var paytype = '04'
        if (this.orderInfo.OrderTitle.indexOf('会员') >= 0) {
          paytype = '20'
        } else if (this.orderInfo.OrderTitle.indexOf('合同模板') >= 0) {
          paytype = '21'
        } else if (this.orderInfo.OrderTitle.indexOf('案件') >= 0) {
          paytype = '09'
        } else if (this.buymember) {
          paytype = '22'
        }
        if (this.yh.CouponType == '会员') {
          yhGuid = '99999999-9999-9999-9999-999999999999'
        }
        var data = {
          "orderGuid": this.orderInfo.OrderGuid,
          "yhGuid": yhGuid,
          "money": this.orderInfo.OrderNeedPay,
          "paytype": paytype,
          "bankname": this.paytype,
          "webremark": this.yh.CouponType == '首单特惠' ? this.orderInfo.OrderTitle + '-首单特惠' : this.orderInfo
            .OrderTitle,
          "pc": ""
        }
        CommonPay(data).then(res => {
          if (res.state == 0) {
            this.returnPage()
          }
        })
      },
      sucessFun(id) {
        clearInterval(payTimer)
        payTimer = setInterval(res => {
          GetPaymentResult({
            "payCustomerId": id
          }).then(res => {
            if (res.state == 0) {
              if (res.data == 1) {
                clearInterval(payTimer)
                _hmt.push(['_trackEvent', 'click', '支付成功', 'pc']);
                this.returnPage()
              }
            }
          })
        }, 1000)

      },
      returnPage() {
        if (this.orderInfo.OrderTitle.indexOf('会员') >= 0) {
          this.isMember().then(res => {
            this.$router.replace('/member')
          })
        } else if (this.orderInfo.OrderTitle.indexOf('合同模板') >= 0) {
          this.$router.back(0)
        } else if (this.orderInfo.OrderTitle.indexOf('案件') >= 0) {
          this.$router.replace('/chatroom')
        } else if (this.orderInfo.LawyerThirdId != '' && this.orderInfo.LawyerThirdId != null) { //定向
          // this.$store.dispatch('onChatType', 'waitd')
          localStorage.setItem('chatroomLawyer', this.orderInfo.LawyerThirdId)
          this.$router.replace('/chatroom')
        } else {
          this.$store.dispatch('onChatType', 'wait')
          localStorage.setItem('chatroomLawyer', '')
          this.$router.replace('/chatroom')
        }
      },
      qrcodeFun(url) {
        console.log(url)
        if (this.qrcode) {
          this.qrcode.clear()
          this.qrcode.makeCode(url)
        } else {
          this.qrcode = new QRCode('qrcode', {
            width: 130, // 设置宽度，单位像素
            height: 130, // 设置高度，单位像素
            text: url // 设置二维码内容或跳转地址
          })
        }

      },
      hideModal() {
        this.showSelect = false
      },
      selectFun() {
        this.showSelect = !this.showSelect
      },
      choseChange(e) {
        console.log(e)
        this.yh = this.yhList[e]
        this.yhlabel=this.yhList[e].name
        this.choseYH(e)
      },
      choseYH(index, type) {
        clearInterval(payTimer)
        if (this.yhList.length > 0) {
          this.yh = this.yhList[index]
          this.yhlabel=this.yhList[index].name
          console.log(this.yh)
          this.showSelect = false
          var p = (Number(this.orderInfo.OrderNeedPay) * 1000 - Number(this.yh.Price) * 1000) / 1000
          this.price = p > 0 ? p : 0
          if (this.yh.Price == 0 && this.yh.CouponsName != '暂不使用优惠') {
            this.price = 0
          }
        } else {
          this.price = this.orderInfo.OrderNeedPay
        }
        if (this.buymember) {
          this.total = (Number(this.price) * 1000 + Number(this.member1.ProductMarketPrice) * 1000) / 1000
        } else {
          this.total = this.price
        }
        this.getPay()
      },
      chosePay(type) {
        this.paytype = type
        this.getPay()
      },
      buymemberFun() {
        this.buymember = !this.buymember
        if (!this.buymember) {
          _hmt.push(['_trackEvent', 'click', '取消开通会员', 'pc']);
        }
      }
    },
    components: {
      FootBox
    }
  }

</script>

<style scoped lang="scss">
  .header {
    width: 940px;
    height: 40px;
    margin: 0 auto;
    position: relative;

    .left {
      line-height: 40px;
      font-size: 14px;
      color: #666666;

      span,
      img {
        margin-right: 10px;
        vertical-align: middle;
      }
    }

    .right {
      position: absolute;
      right: 0;
      top: 8px;
    }
  }

  .banner {
    height: 200px;
    background: #434B75;

    .banner_in {
      width: 940px;
      margin: 0 auto;
      position: relative;

      .word {
        position: absolute;
        top: 60px;
        left: 30px;

        >div:nth-child(1) {
          font-size: 24px;
          color: #FFFFFF;
          margin-bottom: 20px;
        }

        .good {
          .item {
            display: inline-block;
            font-size: 16px;
            color: #FFFFFF;
            margin-right: 50px;

            img {
              margin-right: 7px;
              vertical-align: middle;
            }

            span {
              vertical-align: middle;
            }
          }
        }
      }
    }
  }

  .main {
    background: #F3F3F3;
    padding: 20px 0;

    .main_in {
      width: 940px;
      margin: 0 auto;
      background: #FFFFFF;
      border-radius: 20px;
      box-sizing: border-box;
      padding: 40px 60px;

      .top {
        margin-bottom: 20px;

        .left {
          width: 480px;
          background: #FFFFFF;
          border: 2px solid #F24537;
          border-radius: 10px;
          position: relative;
          box-sizing: border-box;
          padding: 40px 70px;
          text-align: center;
          margin-right: 20px;
          display: inline-block;
          vertical-align: top;

          >img {
            position: absolute;
            bottom: -1px;
            right: -1px;
          }

          >div:nth-child(1) {
            font-size: 24px;
            color: #000000;
            margin-bottom: 10px;
          }

          >div:nth-child(2) {
            font-size: 18px;
            color: #aaa;
            padding-bottom: 30px;
            border-bottom: 1px solid #AAAAAA;
            margin-bottom: 30px;

            div {
              font-size: 24px;
              color: #F24537;
              display: inline-block;

              span {
                font-size: 48px;
                font-weight: bold;
              }
            }
          }

          >div:nth-child(3) {
            >span {
              font-size: 14px;
              color: #333333;
              margin-right: 20px;
            }

            .select {
              display: inline-block;

              position: relative;

              >div:nth-child(1) {
                div {
                  display: inline-block;
                  width: 210px;
                  vertical-align: middle;
                }

                >img {
                  width: 13px;
                  height: 5px;
                  vertical-align: middle;
                }
              }

              .select_div {
                width: 256px;
                // max-height: 300px;
                background: #FFFFFF;
                box-shadow: 0px 0px 4px 0px rgba(6, 0, 1, 0.3000);
                border-radius: 4px;
                position: absolute;
                top: 46px;
                left: 0;
                z-index: 9;

                .item {
                  height: 68px;
                  box-sizing: border-box;
                  padding: 15px;
                  font-size: 14px;
                  color: #333333;
                  position: relative;

                  img {
                    position: absolute;
                    right: 15px;
                    top: 29px;
                  }
                }

                .item:hover {
                  background: #21D0C8;
                  color: #fff;
                }
              }
            }

            .select_no {
              display: inline-block;
              position: relative;
              width: 256px;
              height: 40px;
              background: #FFFFFF;
              border: 1px solid #AAAAAA;
              border-radius: 4px;
              box-sizing: border-box;
              padding: 9px 15px;
              font-size: 14px;
              color: #333333;
              text-align: left;
              cursor: pointer;

              >div:nth-child(1) {
                div {
                  display: inline-block;
                  width: 210px;
                  vertical-align: middle;
                }

                >img {
                  width: 13px;
                  height: 5px;
                  vertical-align: middle;
                }
              }
            }
          }
        }

        .right {
          width: 320px;
          min-height: 280px;
          background: #FFFFFF;
          border: 1px solid #F5F5F5;
          border-radius: 10px;
          box-sizing: border-box;
          padding: 20px;
          display: inline-block;

          .title {
            font-size: 18px;
            color: #000000;
            margin-bottom: 10px;
            text-align: center;
          }

          .tip>div {
            font-size: 16px;
            color: #666666;
            line-height: 17px;
            margin-bottom: 15px;
          }

          .tip>div:last-child {
            margin-bottom: 0px;
          }
        }
      }

      .member {
        width: 820px;
        background: #FFFFFF;
        border: 2px solid #F3F3F3;
        border-radius: 10px;
        margin-bottom: 20px;
        position: relative;
        box-sizing: border-box;
        padding: 50px 0 37px 69px;

        .lt {
          width: 120px;
          height: 32px;
          background: #F24537;
          font-size: 18px;
          color: #FFFFFF;
          border-radius: 10px 0px 10px 0px;
          text-align: center;
          line-height: 32px;
          position: absolute;
          top: -1px;
          left: -1px;
        }

        .rb {
          position: absolute;
          bottom: -1px;
          right: -1px;
        }

        .member_top {
          position: relative;
          margin-bottom: 30px;

          .left {
            >span:nth-child(1) {
              font-size: 24px;
              color: #000000;
              margin-right: 15px;
            }

            >span:nth-child(2) {
              font-size: 48px;
              color: #F24537;
              margin-right: 9px;
              font-weight: bold;
            }

            >span:nth-child(3) {
              font-size: 24px;
              color: #F24537;
              margin-right: 7px;
            }

            >span:nth-child(4) {
              font-size: 18px;
              color: #aaa;
            }
          }

          .right {
            font-size: 18px;
            color: #F24537;
            position: absolute;
            bottom: 4px;
            right: 80px;
          }
        }

        .member_bottom {
          .item {
            display: inline-block;
            text-align: center;
            font-size: 16px;
            color: #333333;
            margin-right: 64px;

            img {
              margin-bottom: 5px;
            }
          }

          .item:last-child {
            margin-right: 0;
          }
        }
      }

      .member.active {
        border: 2px solid #F24537;
      }

      .bottom {
        width: 820px;
        background: #F3F3F3;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 40px 0;
        text-align: center;

        >div:nth-child(1) {
          font-size: 24px;
          color: #333;
          margin-bottom: 10px;
        }

        >div:nth-child(2) {
          font-size: 18px;
          color: #333;
          margin-bottom: 30px;

          span {
            color: #FFA70F;
          }
        }

        .er {
          width: 160px;
          height: 160px;
          background: #FFFFFF;
          border: 1px solid #23AC38;
          border-radius: 10px;
          box-sizing: border-box;
          padding: 15px;
          margin: 0 auto 19px;

          img {
            width: 130px;
            height: 130px;
          }

          iframe {
            overflow: hidden;
          }
        }

        .wxer {
          border: 1px solid #23AC38;
        }

        .zfber {
          border: 1px solid #337AF6;
        }

        .paytype {
          text-align: center;
          margin-bottom: 25px;

          .item {
            padding: 8px 14px;
            border: 1px solid #F3F3F3;
            border-radius: 4px;
            font-size: 14px;
            color: #333333;
            display: inline-block;
            cursor: pointer;

            img {
              vertical-align: middle;
              margin-right: 7px;
            }

            span {
              vertical-align: middle;
            }
          }

          .item:nth-child(1) {
            margin-right: 40px;
          }

          .item.wxactive {
            background: #FFFFFF;
            border: 1px solid #23AC38;
          }

          .item.zfbactive {
            background: #FFFFFF;
            border: 1px solid #337AF6;
          }
        }

        .paybtn {
          width: 300px;
          height: 50px;
          background: #F24537;
          border-radius: 25px;
          font-size: 20px;
          font-weight: bold;
          color: #FFFFFF !important;
          text-align: center;
          line-height: 50px;
          margin: 96px auto 40px;
          cursor: pointer;
        }

        .payprice {
          font-size: 16px;
          color: #AAAAAA;

          div {
            display: inline-block;
            color: #F24537;
            font-size: 16px;

            >span:nth-child(1),
            >span:nth-child(2) {
              font-size: 24px;
            }

            >span:nth-child(1) {
              font-weight: bold;
              font-size: 30px;
            }
          }
        }
      }
    }
  }

</style>
<style lang="scss">
  .cashier {
    .el-select .el-input--suffix .el-input__inner {
      width: 256px;
      height: 40px;
      background: #FFFFFF;
      border: 1px solid #AAAAAA;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 9px 15px;
      font-size: 14px;
      color: #333333;
      text-align: left;
      cursor: pointer;
    }

  }

  .cashier_select {
    .el-select-dropdown__item {
      height: auto;
      line-height: 19px;
      // margin-bottom: 20px;
      padding: 10px 20px;
    }
  }

</style>
